import { ValidateCodeRequestResponse } from './Offramp/api/merchant';
import { STORED_USER, currencyDetailProps } from './constant';
import { currencyInfo, SupportedCurrencies } from './constant';

export const getPaymentParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const amount = searchParams?.get('amount');
  const currency = searchParams?.get('currency');
  const fullname = searchParams?.get('fullname');
  const email = localStorage?.getItem('email') ?? searchParams?.get('email');
  const voucherId = searchParams?.get('voucherId');
  const merchant = searchParams?.get('user') ?? searchParams?.get('merchant');
  const bvn = searchParams?.get('bvn');
  const voucherType = searchParams?.get('voucherType');
  const redeemCurrency = searchParams?.get('redeemCurrency');
  const walletAddress =
    searchParams?.get('address') ??
    (searchParams?.get('redeemAddress') as `0x${string}` | null | undefined);
  const mobileNetwork = searchParams?.get('mobileNetwork');
  const phone = searchParams?.get('phone');
  return {
    bvn,
    email,
    amount,
    currency,
    fullname,
    voucherId,
    merchant,
    voucherType,
    walletAddress,
    redeemCurrency,
    mobileNetwork,
    phone,
  };
};

export const getAuthorizationDetails = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const user = searchParams?.get('user') ?? searchParams?.get('merchant');
  const key = searchParams.get('key');
  return {
    user,
    key,
  };
};

export const getCallBack = () => {
  const params: URLSearchParams = new URLSearchParams(window?.location?.search);
  const callback = params?.get('callback');
  return callback;
};

export const truncateText = (text: string, start?: number, end?: number) => {
  const firstPart = text.slice(0, start || 4);
  const lastPart = text.slice(end || -5);
  return `${firstPart}...${lastPart}`;
};

export const formatTime = (time: any) => {
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

export const formatAmount = (amount: string) => {
  const cleanAmount = amount?.replace(/,/g, '');

  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(cleanAmount ?? 0));
};

export const hasExpired = (timestamp: number) => {
  const currentTime = new Date().getTime();

  if (timestamp <= currentTime) {
    return true;
  } else {
    return false;
  }
};

export const getCurrency = (
  currency: SupportedCurrencies
): currencyDetailProps => {
  return currencyInfo[currency];
};

export const getUserFromLocalStorage = () => {
  const store = localStorage.getItem(STORED_USER);
  if (store) {
    const user = JSON.parse(store) as ValidateCodeRequestResponse;
    return user;
  }
};

interface CountryInfo {
  countryCode: string;
}

const countryPhoneCodeMap: { [key: string]: CountryInfo } = {
  '+233': {
    countryCode: 'GH',
  },
};

export const getCountryByPhoneCode = (phoneCode: string) => {
  return countryPhoneCodeMap[phoneCode];
};
